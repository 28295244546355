jQuery(function($) {

  // 576 - 768 - 992 -1200
  $('.carousel--banners').owlCarousel({
    nav: false,
    dots: false,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4500,
    autoplaySpeed: 1500,
    items: 3,
    responsive: {
      0: {
        items: 3
      },
      360: {
        items: 4
      },
      576: {
        items: 6
      },
      768: {
        items: 8
      },
      1100: {
        items: 9
      },
      1440: {
        items: 12
      },
    }
  });


  // Gallerie: "shadowbox"
  $('.page-gallery').each(function(){
    // Inizializza modal
    var $modal = $('#img-modal');
    if ( !$modal.length ) {
      $modal = $('<div class="modal fade" id="img-modal" aria-hidden="true"></div>')
       .append('<div class="modal-dialog modal-dialog-centered" role="document"><div class="modal-content-wrap"><button class="close" type="button" data-dismiss="modal" aria-label="Chiudi"><svg class="icon"><use xlink:href="/assets/lib/bs-ita/svg/sprite.svg#it-close"></use></svg></button><div class="modal-content"></div></div></div>')
       .appendTo($(document.body))
       .modal({focus:false,show:false});
    }
    var $modalcontent = $modal.find('.modal-content');
    $(this).on('click', '.img-opener', function(e){
      e.preventDefault();
      var imgsrc = $(this).attr('href');
      if ( !imgsrc || ! /^(?:https?:\/\/).*|\.(?:jpe?g|gif|png|webp)$/.test(imgsrc.toLowerCase()) ) return false;
      $modalcontent.html('').append($('<img src="'+imgsrc+'">'))
      $modal.modal('toggle');
    });
  });


  // Modal ricerca
  $('#search-modal')
    .on('show.bs.modal', function(e){ $('body').addClass('modal-open-search'); })
    .on('hidden.bs.modal', function(e){ $('body').removeClass('modal-open-search'); })

});




/* L'a11y di owl-carousel in bs-ita ha errori. Ne correggiamo alcuni. */
(function ($) {
  $('body.home').find('.section-primopiano .it-carousel-all').each(function () {
    function fixDotButtonsA11y($slider, data) {
      data = data || getSliderData($slider);
      $('.owl-dot', $slider).removeAttr('aria-labelledby').attr('aria-label', function (i) {
        return "Notizia " + (i + 1) + (data.items ? " di " + data.items().length : "");
      });
    }
    function getSliderData($slider) {
      return $slider.data('owl.carousel'); // Attenzione: non disponibile fino a dopo l'init?
    }
    var $slider = $(this);
    if ($slider.hasClass('owl-loaded')) {
      fixDotButtonsA11y($slider);
    } else {
      $slider.on('initialized.owl.carousel', function (e) {
        // La dot nav qui non c'è ancora. Viene sì aggiunta a onInitialized, ma dopo questo handler (?).
        // Quindi non possiamo modificarla direttamente.
        // L'handler di bs-ita è sbagliato. Ma siccome l'handler in settings viene eseguito per ultimo, è lì che interveniamo, e lo sostituiamo del tutto.
        e.relatedTarget.settings['onInitialized'] = function () {
          fixDotButtonsA11y($slider, e.relatedTarget);
        };
      });
    }
  })
})(jQuery);